const English = {
  MANDATORY: "Mandatory",
  MOBILE_NUMBER_SHOULD_BE_OF_10_DIGITS: "Mobile number should be of 10 digits",
  USER_NOT_FOUND: "User not found",
  CODE_MUST_BE_OF_6_DIGITS: "Code should be of 6 digits",
  MAXIMUM_RETRIES_EXCEEDED: "Maximum retries exceeded",
  INVALID_CODE: "Invalid code",
  MOBILE_NUMBER: "Mobile Number",
  LOG_IN: "LOG IN",
  VERIFICATION_CODE: "Verification Code",
  VERIFY: "VERIFY",
  BACK_TO_SIGN_IN: "Back to Sign in",
  VERIFICATION_CODE_PAGE_DESC:
    "Enter the 6 digit code received on your mobile number or the email address associated with the mobile number.",
  COPYRIGHT: ` Copyright © 2016-${new Date().getFullYear()} ServiceActivator Inc. All
          rights reserved.`,
};

export default English;
